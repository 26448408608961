import React from "react"
import {
    ExperienceCardBox,
    ExperienceTitleContainer, 
    ExperienceCardBoxContainer,
    Title,
    Date,
    ExperienceDescription,
    DescriptionContainer,    
} from "./Styles"

function ExperienceCard ({title, date, jobTitle, description}) {
    return(
        <>
        <ExperienceCardBoxContainer>
            <ExperienceCardBox>
                <ExperienceTitleContainer>
                    <span>{title}</span>
                    <Date>{date}</Date>
                </ExperienceTitleContainer>
                <ExperienceDescription>    
                    <DescriptionContainer><Title color={"#41bdaf"}>{jobTitle}</Title>{description}</DescriptionContainer>
                </ExperienceDescription>
            </ExperienceCardBox>
        </ExperienceCardBoxContainer>
        </>
    )
}

export default ExperienceCard