import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const defaultLocale = window.location.search.substr(6,7) === "es" ? "es" : "en"

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    "myname": "I'm Dani",
                    "name": "NAME",
                    "developer": "Frontend Developer",
                    "coverDescription": "I help companies and brands to build and improve their online presence through code.",
                    "skills": "SKILLS",
                    "communicationSkill": "COMMUNICATION & CREATIVITY",
                    "experience": "EXPERIENCE",
                    "adevinta": "Frontend developer at InfoJobs developing new functionalities and migrating legacy code to React and testing with React Testing library. Collaborator on the open-source library Sui Components, refactoring and creating demos. Using Scrum as Agile methodologies and Jira as project management tool.",
                    "adevintaCountry": "ADEVINTA - Spain",
                    "adevintaDate": "May 2021 - Current position",
                    "learnlightCountry": "LEARNLIGHT - Spain",
                    "learnlightDate": "September 2019 - May 2021",
                    "learnlight": "Frontend developer of NextGen platform for analytics purposes using technologies such as React, Typescript, Styled Components and GraphQL. Using Scrum and Kanban as Agile methodologies; Jira and Azure DevOps as project management tools; and Bitbucket, as a version control system.",
                    "skylabcodersCountry": "SKYLABCODERS - Spain",
                    "skylabcodersDate":"March 2019 - June 2019",
                    "skylabcoders": "Full Stack developer of Gelato app, to increase the online sales and promote events of an ice cream shop. As a frontend, I used React, Sass and Jest for testing. And as a backend I coded using Express, Node, Mongoose, and used Mocha/Chai for testing.",
                    "watchityCountry": "WATCHITY - Spain",
                    "watchityDate": "May 2016 - March 2019",
                    "watchity": "I developed and executed email marketing campaigns, newsletters and managed social networks. I also created, monitored and analyzed SEM campaigns and created reports with the results. At the same time, I organized and provided support to the company during events and fairs, such as the 4YFN (Mobile World Congress)",
                    "hubiiDate": "July 2014 - April 2016",
                    "hubii": "I was in charge of creating content strategies, updating the corporate blog and emailing marketing campaigns. I did Social Networks Management. I searched for and contacted new possible partners. I created periodic reports about actions performance and competitors status.",
                    "hubiiCountry": "HUBII - Norway",
                    "ftortugaCountry": "Turtle Foundation - Venezuela", 
                    "ftortugaDate": "October 2012 - July 2014",
                    "ftortuga": "My experience at the Turtle Foundation was very rewarding and empowering. Thanks to this opportunity I met with people with great environmental awareness. I collaborated with them as a volunteer in educational activities as well as in the area of journalism, social media management, database and event planning.",
                    "CommunicationalAdvisor": "Communicational  Advisor",
                    "FrontendDeveloper": "Frontend Developer",
                    "FullStackWebDeveloper": "Student - FullStack Web Developer",
                    "SocialMediaManager": "Social Media Manager",
                    "ojoviralDate": "February - October 2011",
                    "ojoviral": "Coordinator of BTL activities during the campaign - Pass the hug - Procter&Gamble' product. Development and execution of social media marketing strategies and management of social networks.",
                    "references": "REFERENCES",
                    "whatPeopleSayAboutMe": "What do people say about me?",
                    "manuelReference": "Her excellent attitude, always with a smile, respectful and generous, has been exemplary from my point of view. Daniela is a skillful and flexible person who, in addition to programming, knows how to work as a team. During the group projects she worked without problems collaborating with her team and contributing pair-programming, as well as individually. Her end-of-course project was an application for a modern ice cream parlor that would allow ordering ice cream online and then picking it up at the physical store using a QR code. It is a full stack application with a real projection. Without going any further, I would recommend Daniela as a programmer and partner in a software development team of an IT company.",
                    "samReference": "Daniela was able to make any new subject that I've thrown at her, her own. Inheriting a complex new application is not easy, but she managed to make it look so! She loves coding so much, that she treats it as a hobby. It's just fun to her! But don't let her positivity deceive you, she's a professional and can only go up from here. I'm proud for having her on my team and I hope our paths cross again! Thanks Dani!",
                    "contactme": "CONTACT ME",
                    "subject": "SUBJECT",
                    "message": "MESSAGE",
                    "send": "SEND",
            }},
            es: {
                translation: {
                    "myname": "Soy Dani",
                    "name": "NOMBRE",
                    "developer": "Frontend Developer",
                    "coverDescription": "Ayudo a empresas y marcas a construir y mejorar su presencia online a través del código." ,
                    "skills": "HABILIDADES",
                    "communicationSkill": "COMUNICACIÓN & CREATIVIDAD",
                    "experience": "EXPERIENCIA",
                    "adevinta": "Frontend Developer en InfoJobs desarrollando nuevas funcionalidades y migrando código legacy a React, testando con la React Testing library. Colaboradora en la libreria de código abierto (Sui Components), refactorizando y creando demos. Utilizando Scrum como metodologías ágiles y Jira como herramienta de gestión de proyectos.",
                    "adevintaDate": "Mayo 2021 - Actual",
                    "adevintaCountry": "ADEVINTA - España",
                    "learnlightCountry": "LEARNLIGHT - España",
                    "learnlightDate": "Septiembre 2019 - Mayo 2021",
                    "learnlight": "Desarrollo frontend de la plataforma NGA en React, Typescript, Styled Components y GraphQL. Utilizando metodologías agiles, Jira y Azure DevOps, como herramientas de gestión de proyectos, y Bitbucket, como sistema de control de versiones.",
                    "skylabcodersCountry":"SKYLABCODERS - España",
                    "skylabcoders": "Desarollo Full Stack de Gelato app. Para la parte de front usé React, Sass (usando la convención de nombres SUIT) y testeé con Jest. En la parte de back utilicé Express, Node, Mongoose, y testeé con Mocha/Chai.",
                    "watchityCountry": "WATCHITY - España",
                    "watchity": "Redacción de contenido para el blog (inglés y español). Desarrollo y ejecución de campañas de email marketing, newsletter y gestión de redes sociales. Organización y apoyo en la participación de eventos y ferias, por ejemplo el 4YFN, en el marco del Mobile World Congress",
                    "hubii": "Búsqueda y contacto con nuevos partners. Desarrollo y ejecución de campañas de email marketing. Encargada de la creación de estrategias de contenido. Seguimiento, análisis de resultados y reporting de campañas.",
                    "hubiiCountry": "HUBII - Noruega",
                    "hubiiDate": "Julio 2014 - Abril 2016",
                    "ftortugaCountry": "FUND. LA TORTUGA - Venezuela", 
                    "ftortugaDate": "Octubre 2012 - Julio 2014",
                    "ftortuga":"Creación de notas de prensa y mantenimiento de las redes sociales. Coordinadora de eventos pro-ambientales. Manejo de base de datos y redacción de proyectos de RSE.",
                    "CommunicationalAdvisor": "Asesora Comunicacional",
                    "FrontendDeveloper": "Frontend Developer",
                    "FullStackWebDeveloper": "Estudiante - FullStack Web Developer",
                    "SocialMediaManager": "Social Media Manager",
                    "ojoviralDate": "Febrero - Octubre 2011",
                    "ojoviral": "Coordinadora de producción de actividades BTL “MUM Pasa el Abrazo en las calles de Caracas” y “MUM Pasa el abrazo en Playa Verde” de la campaña “Pasa el abrazo” de “MUM” producto de Procter&Gamble.",
                    "references": "REFERENCIAS",
                    "whatPeopleSayAboutMe": "¿Qué dicen mis compañeros sobre mí?",
                    "manuelReference": "Su excelente actitud, siempre con una sonrisa, respetuosa y generosa, ha sido desde mi punto de vista, ejemplar. Daniela es una persona hábil y flexible que además de programar sabe trabajar en equipo. Durante los proyectos grupales se desenvolvió sin problemas colaborando con su equipo y aportando pair-programming, como también de forma individual. Su proyecto de final de curso fue una aplicación para una heladería moderna que permitiría ordenar helados online y luego recogerlo en la tienda física mediante un código QR. Se trata de una aplicación full stack con una proyección real. Sin ir más lejos, recomendaría a Daniela como programadora y compañera en un equipo de desarrollo de software de una empresa de IT.",
                    "samReference": "Heredar una nueva aplicación no es fácil, ¡pero ella logró que se viera así! Le encanta programar tanto que hasta lo hace durante su tiempo libre. ¡Es pura diversión para ella! Es una profesional y solo puede subir desde aquí. ¡Estoy orgulloso de tenerla en mi equipo y espero que nuestros caminos se vuelvan a cruzar! ¡Gracias Dani!",
                    "contactme": "CONTÁCTAME",
                    "subject": "ASUNTO",
                    "message": "MENSAJE",
                    "send": "ENVIAR",
                }
            }
        },
        lng: defaultLocale,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });