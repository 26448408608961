import React, { useState } from "react"
import menu from "../../icons/menu.png"
import {useTranslation} from "react-i18next";
import earthSymbol from "../../icons/earth.png"
import {
    HeaderContainer,
    IconContainer,
    HeaderStyles,
    MenuIcon,
    DropdownHeaderLang,
    ColorLetters,
    DropdownMenu,
    DropdownMenuOption,
    LangOption,
    MenuButton,
    MenuOptions,
    MenuContainer,
} from "./Styles"

import { MaxContentContainer } from '../../styles-utils'

function Header() {
    const [dropdownMenu, setDropdownMenu] = useState(false)
    const [dropdownLang, setDropdownLang] = useState(false)
    const {t}  = useTranslation()

    const renderDropdownMenuOption = () => {
        return(
        <>
            <DropdownMenuOption href="#targetSkills">
                    {t('skills')}
            </DropdownMenuOption>
                    <DropdownMenuOption href="#targetExperience">
                    {t('experience')}
            </DropdownMenuOption>
                    <DropdownMenuOption href="#targetReference">
                    {t('references')}
            </DropdownMenuOption>
                    <DropdownMenuOption href="#targetContact">
                    {t('contactme')}
            </DropdownMenuOption>
        </>
        )
    }
    
    const renderLangIcon = () => {
        return(
        <MenuButton position={"relative"} onClick={(e) => setDropdownLang(!dropdownLang)}>
            <MenuIcon src={earthSymbol} alt="earth"/>
            <DropdownHeaderLang dropdownLang={dropdownLang}>
                <LangOption href='?lang=es'>
                    Spanish
                </LangOption>
                <LangOption href='?lang=en' >
                    English
                </LangOption>    
            </DropdownHeaderLang>
        </MenuButton>
        )
    }
    return (
        <>
            <HeaderStyles>
                <MaxContentContainer>
                    <HeaderContainer>
                        <IconContainer>
                            <ColorLetters color={"#7b7fbe"}>CODING</ColorLetters>
                            <ColorLetters>WITH</ColorLetters>
                            <ColorLetters color={"#2faaca"}>DANI</ColorLetters>
                        </IconContainer>
                        <MenuContainer>
                            {renderLangIcon()} 
                            <MenuButton onClick={(e) => setDropdownMenu(!dropdownMenu)}>
                                <MenuIcon alt="hamburger-icon" src={menu} />
                            </MenuButton>
                        </MenuContainer>
                        <MenuOptions>
                            {renderLangIcon()} 
                            {renderDropdownMenuOption()}
                        </MenuOptions>
                    </HeaderContainer>
                </MaxContentContainer>
            </HeaderStyles> 
            <DropdownMenu dropdownMenu={dropdownMenu}>
                {renderDropdownMenuOption()}
            </DropdownMenu>
        </>
    )
}

export default Header