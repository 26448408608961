import React from "react"
import { ReferencesSectionContainer, ReferencesCardContainer } from "./Styles"
import {
    Title,
    TitleContainer
} from "../../styles-utils/index"
import ReferenceCard from "../../components/ReferenceCard/ReferenceCard"
import { useTranslation } from "react-i18next"

const REFERENCES_QUOTES = [
    {   
        id: "1",
        name: "SAMUEL GOMES", job: "Head Of Software Development",
        description: "samReference",
        backColor:"#41bdaf" ,
        profileImage: "/sam.jpg"
    },
    {
        id: "2",
        name: "MANUEL BARZI", job: "Bootcamp team leader",
        description: "manuelReference",
        backColor: "#2FAACA",
        profileImage: "/manuel.jpg"
    },
/*     {
        id: "3",
        name: "DANIELA AGUILERA", job: "web designer",
        description: " Desarrollo frontend de la plataforma NGA en React. Styled Components y GraphQL. Utilizando metodologías agiles, Jira, como herramienta de gestión de proyectos, y Bitbucket, como sistema de control de versiones.",
        backColor: "#8ACFC3",
        profileImage: "/sam.jpg"
    },
    {
        id: "4",
        name: "DANIELA BENITEZ", job: "Web designer",
        description: " Desarrollo frontend de la plataforma NGA en React. Styled Components y GraphQL. Utilizando metodologías agiles, Jira, como herramienta de gestión de proyectos, y Bitbucket, como sistema de control de versiones.",
        backColor: "##7b7fbe",
        profileImage: "/daniela.jpg"
    }, */
]


function References() {
    const {t} = useTranslation()
    return (
        <a name="targetReference">
            <ReferencesSectionContainer>
                <TitleContainer>
                    <Title>{t('references')}</Title>
                </TitleContainer>
                <ReferencesCardContainer>
                {
                    REFERENCES_QUOTES.map(key => {
                        return (
                            <ReferenceCard
                                profileImage={key.profileImage}
                                key={key.id}
                                name={key.name}
                                job={key.job}
                                description={t(key.description)}
                                backColor={key.backColor}
                            />
                        )
                    })
                }
                </ReferencesCardContainer>
            </ReferencesSectionContainer>
        </a>
    )

}

export default References