

export const EXPERIENCE = [
    {
        id: "0", 
        title: "adevintaCountry", 
        date: "adevintaDate",
        jobTitle: "FrontendDeveloper",
        description: "adevinta",
    },
    {
        id: "1", 
        title: "learnlightCountry", 
        date: "learnlightDate",
        jobTitle: "FrontendDeveloper",
        description: "learnlight",
    },
    {
        id: "2",
        title: "skylabcodersCountry", 
        date: "skylabcodersDate",
        jobTitle: "FullStackWebDeveloper",
        description: "skylabcoders",
    },
    {
        id: "3", 
        title: "watchityCountry", 
        date: "watchityDate",
        jobTitle: "SocialMediaManager",
        description: "watchity",
    },
    {
        id: "4",
        title: "hubiiCountry", 
        date: "hubiiDate",
        jobTitle: "SocialMediaManager",
        description: "hubii",
    },
    {
        id: "5",
        title: "ftortugaCountry",
        date: "ftortugaDate",
        jobTitle: "CommunicationalAdvisor",
        description: "ftortuga",
    },
/*     {
        id: "6",
        title: "OJO VIRAL - Venezuela", 
        date: "ojoviralDate",
        jobTitle: "SocialMediaManager",
        description: "ojoviral",
    } */
]