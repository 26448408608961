import styled, {keyframes} from "styled-components"
import TypistUnstyled from 'react-typist'

const blink = keyframes`
  0%{ opacity: 1; }
  50%{ opacity: 0; }
  100%{ opacity: 1; }
`

export const Typist = styled(TypistUnstyled)`
    color: #fff;
    display: inline-flex;
    font-size: 25px;
    font-weight: bold;

    .Cursor {
        opacity: 1;
        animation: ${blink} 1s linear infinite;
    }
    @media (min-width: 1000px) {
        font-size: 5rem;
    }
`

export const CoverImageContainer = styled.div`
    display: none;

    @media (min-width: 1000px) {
        border-radius: 10px;
        display: flex;
        background-image: url('/desktopCover.jpg');
        background-size: cover;
        background-position: right;
        position: relative; 
        height: 700px;
        display: flex; 
        margin-top: 57px;
    }
`

export const CoverDesktopRectangle = styled.div`
    align-items: center;
    @media (min-width: 1000px) {
        background-color: #7b7fbe;
        border-radius: 10px 0 0 10px;
        clip-path: polygon(0 0, 99% 0%, 50% 100%, 0% 100%);
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        opacity: .95;
        position: absolute;
        right: 40%; 
        top: 0px; 
        width: 60%;
        align-items: unset;
        padding-top: 100px;
        padding-left: 70px;
    }

`

export const CoverDesktopTriangle = styled.div`
    @media (min-width: 1000px) {    
        background-color: #2faaca;
        position: absolute;
        right: 0%; 
        top: 0px; 
        height: 100%;
        opacity:0.7;
        width: 100%;
        clip-path: polygon(40% 66%,30% 100%,50% 100%);
    }
`

export const CoverRectangle = styled.div`
    background-image: url('/mobileCover.jpg');
    background-position: top;
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    height: 500px;
    background-repeat: no-repeat;
    justify-content: flex-end; 
    padding: 0 2rem 0 2rem;
    position: relative;
    width: 100%;
    :after {
        position: absolute;
        content: " ";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-color: #7b7fbe;
        opacity: 0.8;
    }

    @media (min-width: 1000px) {
        display: none;
    };
`

export const SecondaryText = styled.span`
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: ${props => props.pb  || ""};
    @media (min-width: 1000px) {
        font-size: 5rem;
    }
`


export const CoverContainer = styled.div`
    margin-top: 45px;;
    @media (min-width: 1000px) {
    padding-left: 16px;
    padding-right: 16px;
    };
    @media (min-width: 1500px) {
        border-radius: 10px;
        overflow: hidden;
        max-width: 1500px;
        margin: 0 auto;
        margin-bottom: 48px;
        margin-top: 15px;;

    }
`

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 100%;
    z-index:1;
    padding-bottom: 100px;

    @media (min-width: 770px) {
       max-width: 350px;
    }

    @media (min-width: 1024px) {
        max-width: 400px;
    }
`

export const DescriptionText = styled.div`
    color: #f9f9f9;
    display: flex;
    font-weight: 600;
    max-width: 340px;
    padding-top: 0;
    font-size: 20px;

    @media (min-width: 1024px) {
        padding-top: 0px;
        max-width: 350px;
    };
`
