import styled from "styled-components"

export const ReferenceCardContainer = styled.div`
    background-color: ${props => props.backColor || "#7b7fbe"};
    color: white;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @media (min-width: 1000px) {
        flex: 0 0 50%;
    }
`  
export const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    place-items: center;
    padding: 2rem;
`

export const ProfileData = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`

export const Name = styled.span`
    font-size: 1.4rem;
    font-weight: bolder;
    padding-top: 10px;
`
export const JobPosition = styled.span`
    color: rgba(255, 255, 255, .6);
    padding-top: 5px;
    font-size: 1.5rem;
`

export const ProfileImage = styled.img`
    height: 8rem;
    width: 8rem;
    background-color: #7b7fbe;
    border-radius: 50%;
    object-fit: cover;
`

export const ProfileDescriptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 500px;
    padding: 0 3rem 3rem;
    position: relative;
`

export const ProfileDescription = styled.span`
    font-size: 1.4rem;
    font-style: italic;
    line-height: 1.5;
`

export const Quotes = styled.img`
    opacity: .1;
    position: absolute;
    height: 6rem;
    left: .5rem;
    top: -3rem;
`



 

