import styled from "styled-components"
import deskBackground from "../../img/deskBackgroundWithColor.jpg" 


export const ExperienceContainer = styled.div`
/*     background-image: url(${deskBackground}); */

    display: flex;
    flex-direction: column;
    align-items: center;

 /*    height: 100%;    
    position: relative;
    width: 100%; */
`

export const MoreIconContainer = styled.div`
    display: flex;
    justify-content: center;

`

export const Triangle = styled.div`
    clip-path: polygon(67% 50%,29% 49%,49% 84%);
    position: absolute;
    width: 20rem;
    height: 10rem;
    top: -5rem;
    right: 9.6rem;
    background-color: white;
`

export const AllCardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1500px;
    
`

