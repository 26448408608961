import React from "react"
import { 
    ExperienceContainer,
    AllCardsContainer
 } from "./Styles"
import { 
    Title, 
    TitleContainer
 } from "../../styles-utils/index"
import ExperienceCard from "../../components/ExperienceCard/ExperienceCard"
import { EXPERIENCE } from "../../data/experienceData"
import { useTranslation } from "react-i18next"

function Experience () {
    const {t} = useTranslation()
    return(
        <a name="targetExperience">
            <ExperienceContainer>
                <TitleContainer>
                    <Title>{t('experience')}</Title>
                </TitleContainer>
                    <AllCardsContainer>
                        {
                            EXPERIENCE.map(key =>{
                                return(
                                    <ExperienceCard 
                                        key={key.id} 
                                        title={t(key.title)} 
                                        date={t(key.date)}
                                        description={t(key.description)}
                                        jobTitle={t(key.jobTitle)}
                                    />
                                )
                            })
                        }
                    </AllCardsContainer>
            </ExperienceContainer>
        </a>
    )
}

export default Experience