import styled from "styled-components"

export const ContactSectionContainer = styled.div`
    background-color: #7b7fbe;
    position: relative;
    min-height: 700px;
`

export const Triangle = styled.div`
    clip-path: polygon(67% 50%,29% 50%,49% 84%);
    position: absolute;
    width: 20rem;
    height: 10rem;
    top: -4.9rem;
    right: 9.6rem;
    background-color: white;
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 80%;
    `

export const FormContainer = styled.div`
    display: flex;
    justify-content: center;
`

export const Input = styled.input`
    border: none;
    font-size: 1.5rem;
    height: 35px;
    color: #7b7fbe; 
    margin-bottom: 20px;
    padding: 6px 12px;
    width: 80%;
    ::placeholder {
        color: #7b7fbe; 
        font-weight: 600;
    }
`
export const Textarea = styled.textarea`
    border: none;
    font-family: 'Montserrat';
    font-size: 1.5rem;
    height: 200px;
    color: #7b7fbe; 
    margin-bottom: 20px;
    padding: 6px 12px;
    width: 80%;
    ::placeholder {
        color: #7b7fbe; 
        font-family: 'Montserrat';
        font-weight: 600;
    }
`