import styled from "styled-components"

export const SkillCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3rem;
    @media (min-width: 850px) {
        display: flex;
        flex-direction: column;
        padding: 32px 20px;
        width: 300px;
    }
`

export const TextContainer = styled.div`
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
    font-weight: bold;
    margin: 1rem;
`

export const TitleAndIconContainer = styled.div`
    display: flex;
    position: relative;
    height: 10rem;
    width: 40%;
    background-color: ${props => props.bc};
    background-image: url('${props => props.backgroundImage}');
    background-repeat: no-repeat;
    background-position: 90% 10%;
    background-size: 4.8rem;
    border-radius: 6px 0 0 6px;
    box-shadow: 0.1rem 1rem 1rem 0 rgba(100,110,140,.4);
    @media (min-width: 850px) {
        width: 100%;
        border-radius: 6px 6px 0 0;
        box-shadow: none;
    }

`

export const DescriptionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
    width: 45%;
    background-color: white;
    box-shadow: 0.5rem 1.25rem 1.75rem 0 rgba(100,110,140,.4);
    border-radius: 0 6px 6px 0;
    @media (min-width: 850px) {
        width: 100%;
        border-radius: 0 0 6px 6px;
    }
`
export const Text = styled.span`
    color: white;
    font-weight: bold;
`

export const Triangle = styled.div`
    background-color: ${props => props.bc};
    clip-path: polygon(100% 45%,0 0,0 100%);
    height: 2rem;
    left: 97%;
    margin-top: -1rem;
    position: absolute;
    top: 50%;
    width: 1rem;
    @media (min-width: 850px) {
        clip-path: polygon(50% 51%,0 0,100% 0);
        left: 45%;
        top: 10.9rem;
        width: 2rem;
    }

`
export const Description = styled.div`
    padding: 10px 20px;
    font-size: 13px;
    text-align: center;
`

export const CardsContainer = styled.div`
    @media (min-width: 850px) {
        display: flex;
        flex-direction: row;
        overflow: auto;
        width: 100%;
        scroll-snap-type: x mandatory;
        justify-content: center;
        ::-webkit-scrollbar { 
            display: none; 

        } 
    }
`