import styled from 'styled-components'


export const Title = styled.span `
    font-size: 2.4rem;
    font-weight: bold;
    color: ${props => props.color || "#7b7fbe"};
    @media (min-width: 768px) {
        font-size: 3rem;
  }
`

export const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 3rem 0 3rem 0;
    flex-direction: column;
    align-items: center
`

export const Layout = styled.div`
    width: 100%;
    height: 100vh;

`
export const SectionsContainer = styled.div`
    width: 100%;
`

export const Triangle = styled.div`
    clip-path: polygon(67% 50%,29% 49%,49% 84%);
    position: absolute;
    width: 20rem;
    height: 10rem;
    top: -5.6rem;
    right: 9.6rem;
    background-color: white;
`

export const MaxContentContainer = styled.div`
    width: 100%;
    @media (min-width: 1500px) {
        margin: 0 auto;
        max-width: 1500px;
    }
`