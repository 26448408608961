import React, {useState} from "react"
import {
    ContactSectionContainer,
    Form,
    Input,
    Textarea,
    FormContainer,
} from "./Styles"
import {
    Title,
    TitleContainer
} from "../../styles-utils/index"
import Button from "../../components/Button/Button"
import SMChannels from "../../components/SMChannels/SMChannels"
import { useTranslation } from "react-i18next"

function Contact() {
    const {t} = useTranslation()
    const [status, setStatus] = useState({status: undefined})

    const submitForm =(event) => {
        event.preventDefault();
        const form = event.target;
        console.log(form)
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== XMLHttpRequest.DONE) return;
           if (xhr.status === 200) {
            form.reset();
            setStatus({ status: "SUCCESS" });
          } else {
            setStatus({ status: "ERROR" });
          } 
        };
        xhr.send(data);
      }


    return (
        <a name="targetContact">
            <ContactSectionContainer>
                        <TitleContainer>
                            <Title color="white">{t('contactme')}</Title>
                            <SMChannels displayMobile={"flex"} displayDesktop={"flex"}/>
                        </TitleContainer>
                    <FormContainer>
                        <Form 
                            action="https://formspree.io/f/meqpejza" 
                            method="POST" 
                            onSubmit={(event) => submitForm(event)}>
                            <Input
                                type="text"
                                id="fname"
                                name="name"
                                placeholder={t('name')}
                                required
                            />
                            <Input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="EMAIL"
                                required
                            />
                            <Input
                                type="text"
                                id="subject"
                                name="subject"
                                placeholder={t('subject')}
                                required
                            />
                            <Textarea
                                id="emailContent"
                                name="emailContent"
                                placeholder={t('message')}
                                required
                            >
                            </Textarea>
                            <Button value="Send" type="submit" backColor={"#41bdaf"} />
                        </Form>
                </FormContainer>
            </ContactSectionContainer>
        </a>
    )
}

export default Contact