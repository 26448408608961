import styled from 'styled-components'

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    padding: 6px 2rem 6px 2rem;
   
`;

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 3.3rem;
    font-size: 2rem;
`;

export const MenuIcon = styled.img`
    height: 1.8rem;
    width: 1.8rem;

`

export const MenuContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 3.3rem;
    font-size: 2rem;
    @media (min-width: 1023px) {
        display: none;
  }

`

export const MenuButton = styled.button`
    align-content: center;
    border: 0;
    background: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: .5rem;
    padding-left: 15px;
    position: ${props => props.position};
`

export const MenuOptions = styled.div`
    display: none;
    @media (min-width: 1023px) {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 3.3rem;
        font-size: 2rem;
            
    }   
`

export const ColorLetters = styled.span`
    color: ${props => props.color ? props.color : "gray"};
    font-weight: bold;
`

export const DropdownMenu = styled.ul`
    background: white;
    border-top: 2px solid #41bdaf;
    display: flex;
    flex-direction: column;
    height: ${props => props.dropdownMenu ? "234px" : "0"};
    margin: 0;
    opacity: ${props => props.dropdownMenu ? 1 : 0};
    overflow: hidden;
    padding: 0;
    transition: all .3s ease;
    width: 200px;
    position: fixed;
    top: 45px;
    right: 0;
    z-index: 2;
  /*   scroll-behavior: smooth; */
    @media (min-width: 1023px) {
        display: none;
  }
`

export const DropdownHeaderLang = styled.ul`
    background: white;
    position: absolute;
    border-top: 2px solid #41bdaf;
    display: flex;
    flex-direction: column;
    margin: 0;
    opacity: ${props => props.dropdownLang ? 1 : 0};
    overflow: hidden;
    padding: 0;
    transition: all .3s ease;
    width: 100px;
    position: fixed;
    top: 45px;
    z-index: 2;
    
    @media (min-width: 1000px) {
        top: 56px;
    }



`

export const LangOption = styled.a`
    list-style-type: none;
    text-decoration: none;
    border-bottom: 2px solid rgba(0,0,0,.03);
    font-size: 13px;
    color: #41bdaf;
    font-weight: 900;
    padding: 10px;
    &:hover {
        background-color: #41bdaf;
        color: white;
  };
`

export const DropdownMenuOption = styled.a`
    list-style-type: none;
    padding: 20px;
    border-bottom: 2px solid rgba(0,0,0,.03);
    font-size: 13px;
    color: #41bdaf;
    font-weight: 900;
    text-decoration: none;

    &:hover {
        background-color: #41bdaf;
        color: white;
  };
  @media (min-width: 1023px) {
    padding: 6px 20px;
    border-bottom: none;
  }
`


export const HeaderStyles = styled.div`
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, .85);
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 0;
    position: fixed;
    z-index: 2;
`