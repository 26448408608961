import React  from "react"
import {useTranslation} from "react-i18next";
import { 
    CoverRectangle,
    CoverDesktopRectangle,
    CoverDesktopTriangle,
    SecondaryText,
    DescriptionText,
    CoverImageContainer,
    CoverContainer,
    Typist,
    TextContainer,
} from "./Styles"

import SMChannels from "../SMChannels/SMChannels"

export default function Cover () {
    const {t}  = useTranslation()

    const CoverText = () => {
        return(
            <TextContainer>
                <SecondaryText>
                    <Typist> {t('myname')} 👋</Typist>
                </SecondaryText>
                <SecondaryText pb="20px" >
                    {t('developer')} 
                </SecondaryText>
                <DescriptionText> 
                    {t('coverDescription')}
                </DescriptionText>
            </TextContainer>
        )
    } 
    return (
        <CoverContainer>
            <CoverRectangle>
                {CoverText()}
            </CoverRectangle>
            <CoverImageContainer>
                <CoverDesktopRectangle>
                    {CoverText()}
                </CoverDesktopRectangle> 
                <CoverDesktopTriangle/>
            </CoverImageContainer>
    {/*             <SMChannels displayDesktop={"none"} displayMobile={"flex"}/> */}
        </CoverContainer>
    )
}