import React from "react"
import {ButtonStyles, ButtonContainer} from "./Styles"
import i18n from "i18next";

function Button ({color, backColor}){
    return(
        <ButtonContainer>
            <ButtonStyles color={color} backColor={backColor}>
                {i18n.t('send')}
            </ButtonStyles>
        </ButtonContainer>
    )
}

export default Button