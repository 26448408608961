import styled from "styled-components"

export const ExperienceCardBox = styled.div`
    border-radius: 6px;
`

export const ExperienceCardBoxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    padding: 10px 40px 40px 40px;
/*     padding-bottom: 30px; */
    @media (min-width: 768px) {
        padding-bottom: 60px;
        flex-shrink: 0;
    } 

`

export const ExperienceTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: #7b7fbe;
    font-weight: bold;
    font-size: 1.6rem;
    padding-bottom: 10px;
    border-radius: 6px 6px 0 0;

    @media (min-width: 768px) {
        width: 330px; 
    }
`

export const Title = styled.div`
    font-size: 20px;
    font-weight: 900;
    color: #41bdaf;
    margin-bottom: 8px;
`

export const Date = styled.div`
    font-style: italic;
    font-size: 1rem;

`
export const JobTitle = styled.div`

`
export const ExperienceDescription = styled.div`
    font-size: 14px;
    font-weight: 500;
/*     min-height: 30rem; */
    text-align: left;
    background-color: white;
/*     padding-bottom: 20px; */
    @media (min-width: 768px) {
        width: 330px; 
    }

`

export const DescriptionContainer = styled.span`
    line-height: 22px;

`
