import React  from "react"
import { Circle, SMChannelsContainer } from "./Styles"
import linkedinIconSrc from "../../icons/linkedin-icon.png"
import instagramIconSrc from "../../icons/instagram-icon.png"
import twitterIconSrc from "../../icons/twitter-icon.png"

const smChannelsInfo = [
    {
        href: "https://www.linkedin.com/in/aguileramalave/",
        backgroundImage: linkedinIconSrc,
        target:"_blank"
    },
    {
        href: "https://twitter.com/codingwithdani",
        backgroundImage: twitterIconSrc,
        target:"_blank"
    },
    {
        href: "https://www.instagram.com/codingwithdani/",
        backgroundImage: instagramIconSrc,
        target:"_blank" 
    }
]

const SMChannels = ({displayDesktop, displayMobile}) => {
    return(
        <SMChannelsContainer displayDesktop={displayDesktop} displayMobile={displayMobile}>
            {
                smChannelsInfo.map(key => {
                    return(
                        <Circle 
                        href={key.href}
                        backgroundImage={key.backgroundImage}
                        target={key.target}
                    />         
                )})
            }
        </SMChannelsContainer>
    )
}

export default SMChannels