import React from "react"
import {
    SkillCardContainer,
    TextContainer,
    TitleAndIconContainer,
    DescriptionContainer,
    Text,
    Description,
    Triangle,
    CardsContainer
} from "./Styles"
import frontendIconSource from "../../icons/frontend-icon.png"
import backendIconSource from "../../icons/backend-icon.png"
import agileIconSource from "../../icons/agile-icon.png"
import testingIconSource from "../../icons/testing-icon.png"
import ideasIconSource from "../../icons/ideas-icon.png"

const SKILLS_TITLE = [
    { first: "FRONTEND",
     second: "DEVELOPER", 
     icon: frontendIconSource, 
     description:  "Js - React - TypeScript - Css - SASS - Styled Components - HTML5 - Storybook ", 
     bc: "#7b7fbe;"
    },
    { first: "BACKEND",
     second: "DEVELOPER",
     icon: backendIconSource, 
     description: "NodeJS - GraphQL - Express - MongoDB - Mongoose",
     bc: "#2FAACB" 
    },
    { first: "AGILE", 
    second: "MINDSET", 
    icon: agileIconSource, 
    description: "Jira - Bitbucket - Git - Azure DevOps",
    bc: "#41BDAF"
    },
    { first: "TESTING", 
    second: "AUTOMATION", 
    icon: testingIconSource, 
    description: "Jest - Mocha/Chai - React Testing Library",
    bc: "#8ACFC3"
    },
]


function SkillCard() {

    const renderSkillCard = () => (
        SKILLS_TITLE.map(key => {
            return (
                <SkillCardContainer key={key.first}>
                    <TitleAndIconContainer bc={key.bc} backgroundImage={key.icon}>
                        <Triangle bc={key.bc} />
                        <TextContainer>
                            <Text>{key.first}</Text>
                            <Text>{key.second}</Text>
                        </TextContainer>
                    </TitleAndIconContainer>
                    <DescriptionContainer>
                        <Description>{key.description}</Description>
                    </DescriptionContainer>
                </SkillCardContainer>
            )
        })
    )


    return (
        <CardsContainer>
                {renderSkillCard()}
        </CardsContainer>
    )

}

export default SkillCard