import styled from "styled-components"

export const ButtonStyles = styled.button`
    background-color: ${props => props.backColor || "#7b7fbe"};
    color: white;
    cursor: pointer;
    font-size: 15px;
    line-height: 2.5;
    padding: 0;
    text-align: center;
    width: 100px;
    border: none;
    font-weight: bold;
    `

export const ButtonContainer = styled.div`
    margin-bottom: 100px;

`