import React from "react"
import {
    ReferenceCardContainer,
    ProfileContainer,
    Name,
    JobPosition, 
    ProfileImage,
    ProfileDescriptionContainer,
    ProfileDescription,
    Quotes,
    ProfileData
} from "./Styles"
import QuoteIcon from "../../icons/quotes-icon.png"

function ReferenceCard ({name, job, description, backColor, profileImage}) {
    return(
        <ReferenceCardContainer backColor={backColor}>
            <ProfileContainer>
                <ProfileImage src={profileImage} alt={"ProfileImage"} />
                <ProfileData>
                    <Name>{name}</Name>  
                    <JobPosition>{job}</JobPosition> 
                </ProfileData>
            </ProfileContainer>
            <ProfileDescriptionContainer>
                <Quotes alt="quoteIcon" src={QuoteIcon}/>
                <ProfileDescription>
                    {description}
                </ProfileDescription>
            </ProfileDescriptionContainer>
        </ReferenceCardContainer>
    )
}

export default ReferenceCard