import styled from 'styled-components'

export const SkillsContainerStyles = styled.div`
    position: relative;
    padding-bottom: 2rem;

    
`
export const Triangle = styled.div`
clip-path: polygon(50% 51%, 0 0, 100% 0);
    position: absolute;
    width: 7rem;
    height: 5rem;
    background-color: white;
    left: 50%;
    margin-left: -3.5rem;
    top: -.1rem;
    right: 37.6rem;
    @media (min-width: 770px) {
        display: none;
    };

`

