import styled from "styled-components"

export const ReferencesSectionContainer = styled.div``

export const Triangle = styled.div`
    clip-path: polygon(67% 50%,29% 49%,49% 84%);
    position: absolute;
    width: 20rem;
    height: 10rem;
    top: -4.8rem;
    right: 9.6rem;
    background-color: white;
`

export const ReferencesCardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

