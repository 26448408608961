import styled from 'styled-components'

export const Circle = styled.a`
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    background-color: #7b7fbe;
    background-size: 50%;
    background-position: center;
    background-image: url('${props => props.backgroundImage}');
    background-repeat: no-repeat;
    @media (min-width: 1000px) {
        height: 5rem;
        width: 5rem;
    }
`
export const SMChannelsContainer = styled.div`
    display: ${props => props.displayMobile};
    height: 6rem;
    flex-direction: row;
    justify-content: space-evenly;
    @media (min-width: 1000px) {
        display: ${props => props.displayDesktop};
        justify-content: center;
        width: 100%;
        height: 6rem;
    };

`