import React from "react"
import { Title, TitleContainer } from "../../styles-utils"
import { SkillsContainerStyles } from "./Styles"
import  SkillCard  from "../../components/SkillCard/SkillCard"
import { useTranslation } from "react-i18next"

const Skills = ({}) => {
    const {t} = useTranslation()
    return(
        <a name="targetSkills">
            <SkillsContainerStyles>
                <TitleContainer>
                    <Title>{t('skills')}</Title>
                </TitleContainer>
                    <SkillCard />
            </SkillsContainerStyles>
        </a>
    )
}

export default Skills


