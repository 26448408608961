import React from 'react';
import Header from "./components/Header/Header"
import Cover from "./components/Cover/Cover"
import Skills from "./sections/Skills/Skills"
import Experience from "./sections/Experience/Experience"
import References from "./sections/References/References"
import Contact from "./sections/Contact/Contact"
import { Layout, SectionsContainer } from "../src/styles-utils"
import './App.css';

function App() {
  return (
    <Layout className="App">
      <Header/>
      <SectionsContainer>
        <Cover/>
        <Skills/>
        <Experience/>
        <References/>
        <Contact/>
      </SectionsContainer>
    </Layout>
  );
}

export default App;
